import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71')
];

export const server_loads = [0,2,9,11,17,18];

export const dictionary = {
		"/(public)": [~26,[5]],
		"/about": [39],
		"/(auth)/auth": [19,[2]],
		"/(auth)/auth/forgotpassword": [~20,[2]],
		"/(auth)/auth/onboarding": [~21,[2,3]],
		"/(auth)/auth/signin": [~22,[2]],
		"/(auth)/auth/signout": [~23,[2]],
		"/(auth)/auth/signup": [~24,[2]],
		"/(auth)/auth/signup/partner": [~25,[2]],
		"/(public)/company/[slug]": [~27,[5],[,6]],
		"/(public)/fw/[slug]": [~28,[5],[,7]],
		"/(public)/getting-started": [29,[5,8]],
		"/(public)/getting-started/[slug]": [~30,[5,8]],
		"/(public)/marketplace": [31,[5,9]],
		"/(public)/marketplace/[slug=MarketCategory]": [~32,[5,9]],
		"/my/(app)": [~40,[11]],
		"/my/(app)/account": [~41,[11]],
		"/my/(app)/account/company": [42,[11,12]],
		"/my/(app)/account/company/page": [43,[11,12],[,,13]],
		"/my/(app)/account/company/page/[block]": [~44,[11,12],[,,13,14]],
		"/my/(app)/account/dashboard": [45,[11]],
		"/my/(app)/account/inbox": [~46,[11,15]],
		"/my/(app)/account/inbox/drafts": [47,[11,15]],
		"/my/(app)/account/inbox/sent": [48,[11,15]],
		"/my/(app)/account/inbox/snoozed": [49,[11,15]],
		"/my/(app)/account/jobs": [~50,[11],[,16]],
		"/my/(app)/account/jobs/new": [~55,[11],[,16]],
		"/my/(app)/account/jobs/[id]": [~51,[11,,17],[,16]],
		"/my/(app)/account/jobs/[id]/applicant": [52,[11]],
		"/my/(app)/account/jobs/[id]/edit": [~53,[11,,17],[,16]],
		"/my/(app)/account/jobs/[id]/view": [54,[11,,17],[,16]],
		"/my/(app)/account/leads": [56,[11]],
		"/my/(app)/account/send": [~57,[11]],
		"/my/(app)/account/settings": [~58,[11,18]],
		"/my/(app)/account/settings/account": [~59,[11,18]],
		"/my/(app)/account/settings/business": [60,[11,18]],
		"/my/(app)/account/settings/display": [~61,[11,18]],
		"/my/(app)/account/settings/notifications": [~62,[11,18]],
		"/my/(app)/account/settings/profile": [~63,[11,18]],
		"/my/(app)/account/settings/team": [~64,[11,18]],
		"/my/(app)/account/subscribe/[slug]": [~65,[11]],
		"/my/(app)/account/team": [~66,[11]],
		"/my/(app)/account/team/add": [67,[11]],
		"/my/(app)/account/update-email": [69,[11]],
		"/my/(app)/account/update-password": [~70,[11]],
		"/my/(app)/account/update": [68,[11]],
		"/(public)/newsletter": [~33,[5]],
		"/(public)/posts": [~34,[5,10]],
		"/(public)/posts/legal": [36,[5,10]],
		"/(public)/posts/legal/[slug]": [~37,[5,10]],
		"/(public)/posts/[slug]": [~35,[5,10]],
		"/(public)/prices": [38,[5]],
		"/sentry-example": [71]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';
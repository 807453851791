import type { ParamMatcher } from '@sveltejs/kit';


const validParams = [
    'testimonials',
    'intro',
];


/**
 * Checks if the given parameter matches any valid parameter.
 * @param param The parameter to be checked.
 * @returns True if the parameter matches any valid parameter, false otherwise.
 */
export const match: ParamMatcher = (param) => {

    return validParams.includes(param);
};

// import * as Sentry from '@sentry/sveltekit';
import { init } from '@jill64/sentry-sveltekit-cloudflare/client'

export async function handleError({ error, event }) {
    // you can capture the `error` and `event` from the client
    // but it only runs if the unexpected error comes from `+page.ts`
    console.log(error)
  
    return {
      // don't show sensitive data to the user
      message: 'Yikes! 💩',
    }
  }

// If you don't want to use Session Replay, remove the `Replay` integration, 
// `replaysSessionSampleRate` and `replaysOnErrorSampleRate` options.
// Sentry.init({
//     dsn: "https://45bb59bba9f7891b9161eb8a22bdd7fd@o4507540210974720.ingest.de.sentry.io/4507540983906384",
//     tracesSampleRate: 1,
//     replaysSessionSampleRate: 0.1,
//     replaysOnErrorSampleRate: 1,
//     integrations: [Sentry.replayIntegration()]
// })

// import { errorForTesting } from './tesingErrors'

// const onError = init(
//   'https://45bb59bba9f7891b9161eb8a22bdd7fd@o4507540210974720.ingest.de.sentry.io/4507540983906384',
//    {
//     sentryOptions: {
//     tracesSampleRate: 1,
//     replaysSessionSampleRate: 0.1,
// //       beforeSend: (event) =>
// //         errorForTesting.includes(event.exception?.values?.[0].value ?? '')
// //           ? null
// //           : event
//     }
//   }
// )

// export const handleError = onError((_, sentryEventId) => ({
//   message: `This error was successfully sent to Sentry. 
//   /n message: ${_.message}.
//   /n event: ${JSON.stringify(_.event).toString()}.
//   /n stack: ${JSON.stringify(_.error).toString}.
//   /n Please contact support for further assistance. `,
//     sentryEventId
//   }))
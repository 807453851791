import type { ParamMatcher } from '@sveltejs/kit';


const validParams = [
    'technology',
    'sales',
    'hr',
    'human_resources',
    'finance',
    'legal',
    'accounting',
    'it',
    'strategy',
    'marketing'
];


/**
 * Checks if the given parameter matches any valid parameter.
 * @param param The parameter to be checked.
 * @returns True if the parameter matches any valid parameter, false otherwise.
 */
export const match: ParamMatcher = (param) => {

    return validParams.includes(param);
};
